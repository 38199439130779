.reporting {
    display: grid;
    grid-template-columns: max-content 1fr;
    min-height: 0;
    font-family: $font-family-main;
    font-size: $font-size-p;
    font-weight: 400;
    color: $color-text-main;
    background-color: $color-light;
}

.reporting__links {
    width: fit-content;
    margin: 0;
    padding: 20px;
    list-style: none;
    background-color: $color-white;
    border-right: 1px solid $divider;

    .reporting__link {
        display: inline-block;
        margin-bottom: 10px;
        color: $primary;
        white-space: nowrap;
        font-size: 1.25em;
        text-decoration: none;
        border-bottom: 1px solid transparent;
        outline: none;

        &:hover {
            border-bottom-color: $primary;
        }

        &--active,
        &--active:hover {
            cursor: default;
            color: currentColor;
            border-bottom-color: transparent;
        }
    }
}

.reporting__main {
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 0;
}

.reporting__menu {
    width: 100%;
    height: fit-content;
    padding: 0 20px 20px 20px;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 40px;
    overflow-x: auto;

    & *,
    &-select-item {
        font-family: $font-family-main;
        font-size: $font-size-p;
        font-weight: 400;
        color: $color-text-main;
    }

    &-filter > span {
        display: inline-block;
        margin-bottom: 4px;
        font-weight: bold;
        font-size: 1.05em;
    }

    &-filter input {
        width: 140px !important;
    }

    &-select-item {
        padding-right: 20px;
    }

    &-select-button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: none !important;
        background-color: $color-white !important;
        border: 1px solid $color-break-main;
        cursor: pointer;
        outline: none;

        &:disabled {
            background-color: rgba($color-break-main, 0.3) !important;
            pointer-events: none;
            cursor: not-allowed;
        }
    }

    & .bp5-multi-select {
        width: 240px;
        box-shadow: none;
        outline: none;
    }

    & .bp5-tag-input-values {
        flex-wrap: nowrap;
        overflow: auto;
    }
}

.reporting__content {
    position: relative;
    width: 100%;
    margin-top: -20px;
    display: flex;
    overflow-y: auto;
    min-height: 0;

    &-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.reporting__stats {
    flex-shrink: 0;
    width: fit-content;
    padding: 20px;
    min-height: 0;

    &-title {
        margin-bottom: 0.25rem;
        white-space: nowrap;
    }
    &-value {
        margin-bottom: 1.5rem;
        font-size: 1.5rem;
        font-weight: 300;;
    }
}

.reporting__charts {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex-grow: 1;

    &-main {
        min-height: 300px;
        width: 100%;
        height: 60%;
    }

    &-additional {
        width: 100%;
        flex-grow: 1;
        display: flex;
        justify-content: space-between;

        .--pathways,
        .--services,
        .--admin-types {
            width: 32%;
        }
    }
}
